import { Controller } from "@hotwired/stimulus"

import 'jquery'
import moment from 'moment';

// var $ = require("jquery");
// var moment = require("moment")


export default class extends Controller {
  static targets = [ "notifications", "unread", "notification" ]

  connect() {
    // var $this = this
    // var account = $('.account').data('account');
    // var request = $.ajax ({
    //   url: `/${account}/notifications`,
    //   dataType: 'JSON',
    //   method: "GET"
    // })

    // request.done(function(data) {
    //   $(data).each(function(){
    //     $($this.notificationsTarget).append(this.template)
    //   })
    //   var unread = data.filter(d => d.read == false);

    //   if (unread.length > 0) {
    //     $($this.unreadTarget).show()
    //   } else {
    //      $($this.unreadTarget).hide()
    //   }
    // })

    // window.refresh = window.setInterval(function(){
    //   var request = $.ajax ({
    //     url: `/${account}/notifications`,
    //     dataType: 'JSON',
    //     method: "GET"
    //   })

    //   request.done(function(data) {
    //     $($this.notificationsTarget).html("")

    //     $(data).each(function(){
    //       $($this.notificationsTarget).append(this.template)
    //     })
    //     var unread = data.filter(d => d.read == false);

    //     if (unread.length > 0) {
    //       $($this.unreadTarget).show()
    //     } else {
    //        $($this.unreadTarget).hide()
    //     }
    //   })
    // }, 60000);
    // refresh every minute
  }

  getNotifications() {
    var $this = this
    var account = $('.account').data('account');
    var request = $.ajax ({
      url: `/${account}/notifications`,
      dataType: 'JSON',
      method: "GET"
    })

    request.done(function(data) {
      $($this.notificationsTarget).html('')

      $(data).each(function(){
        $($this.notificationsTarget).append(this.template)
      })

      var unread = data.filter(d => d.read == false);
      if (unread.length > 0) {
        $($this.unreadTarget).show()
      } else {
         $($this.unreadTarget).hide()
      }
    })
  }

  openNotification(){
    var $this = this
    // var csrf_token = $('meta[name="csrf-token"]').attr('content');
    var account = $('.account').data('account');
    var id = $(event.target).closest('[data-id]').data('id')

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var request = $.ajax ({
      url: `/${account}/notifications/${id}`,
      data: {
        'notification[read_at]': moment().format()
      },
      dataType: 'script',
      method: "PATCH"
    })

    var request2 = $.ajax ({
      url: `/${account}/notifications`,
      dataType: 'JSON',
      method: "GET"
    })

    request2.done(function(data) {
      $($this.notificationsTarget).html("")

      $(data).each(function(){
        $($this.notificationsTarget).append(this.template)
      })
      var unread = data.filter(d => d.read == false);

      if (unread.length > 0) {
        $($this.unreadTarget).show()
      } else {
         $($this.unreadTarget).hide()
      }
    })


  }

  markAsRead(){
    var $this = this
    // var csrf_token = $('meta[name="csrf-token"]').attr('content');
    var account = $('.account').data('account');
    var id = $(event.target).closest('[data-id]').data('id')

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var request = $.ajax ({
      url: `/${account}/notifications/mark_as_read`,
      dataType: 'script',
      method: "PATCH"
    })
    
    var request2 = $.ajax ({
      url: `/${account}/notifications`,
      dataType: 'JSON',
      method: "GET"
    })

    request2.done(function(data) {
      $($this.notificationsTarget).html("")

      $(data).each(function(){
        $($this.notificationsTarget).append(this.template)
      })
      var unread = data.filter(d => d.read == false);

      if (unread.length > 0) {
        $($this.unreadTarget).show()
      } else {
         $($this.unreadTarget).hide()
      }
    })
  }

  disconnect() {
    // clearInterval(refresh)
  }
}