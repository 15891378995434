
import { Controller } from "@hotwired/stimulus"
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  // static targets = [ "all", "event" ]

  connect() {

    var account = $('.account').data('account');
    var q =  $('#params').data('params')
    var project_id =  $('#project_id').val()
    var customer_id =  $('#customer_id').val()
    var customer_group_id =  $('#group_id').val()
    var date_from =  $('#from').val()
    var date_to =  $('#to').val()

    $('#project_q').keyup(delay(function (e) {
      // console.log('Time elapsed!', this.value);
      $('#q_submit').click();

    }, 200));


    function delay(callback, ms) {
      var timer = 0;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, ms || 0);
      };
    }

    var request = $.ajax ({
      url: `/${account}/billable_projects`,
      dataType: 'script',
      data: {
        q: q,
        project_id: project_id,
        customer_id: customer_id,
        group_id: customer_group_id,
        from: date_from,
        to: date_to
      },
      method: "GET"
    })


    request.done(function() {
      var options = {
        valueNames: [ 'name', 'customer']
      };

      var projectList = new List('project_list', options);
    })

  }

}