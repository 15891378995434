import { Controller } from "@hotwired/stimulus";
// import {select2} from "select2"
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = [ "select" ]
  static values = {emptysubmit: String}

  connect() {
    let $this = this

    new TomSelect(this.selectTarget,{
      // sortField: 'text',
      onDropdownClose: $this.submit.bind($this),
    });


  }

  submit() {
      // $("#filter_submit").click()

    if(this.emptysubmitValue == "true") {
      $("#filter_submit").click()
    } else if (this.selectTarget.value) {
        $("#filter_submit").click()
    }

  }
}