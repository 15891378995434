import { Controller } from "@hotwired/stimulus"

var $ = require("jquery");

export default class extends Controller {
  static targets = [ "customer", "project"]


  setCustomer(name, projects) {
    // console.log(name, JSON.stringify(projects))
    var $projects = JSON.stringify(projects)
    $(this.projectTarget).val(null)
    $(this.projectTarget).siblings('.selectize-control').find('.selectize-dropdown').find('.selectize-dropdown-content').find('.optgroup').hide()
    $(this.projectTarget)[0].selectize.clearOptions();
    $(this.projectTarget)[0].selectize.clear(true); 
    var project_select = $(this.projectTarget)[0].selectize

    $(projects).each(function(){
      project_select.addOption(this);
    })
    // $(this.projectTarget)[0].selectize.load(function(callback) {
    //   console.log(JSON.stringify($projects))
    //   callback(JSON.stringify($projects))
    // })
    // $(this.projectTarget).siblings('.selectize').find("[data-optgroup='" + name + "']").show()
  }
}