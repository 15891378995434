import { Controller } from "@hotwired/stimulus"

// import { Controller } from "stimulus"
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  static targets = [ "all", "event" ]

  connect() {
    var options = {
      valueNames: [ 'name']
    };

    var teamList = new List('team_list', options);
  }
}