import { Controller } from "@hotwired/stimulus"
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  // static targets = [ "all", "event" ]

  connect() {
    var options = {
      valueNames: [ {name: 'name', attr: 'data-name'}, {name: 'category', attr: 'data-category'}, {name: 'customer', attr: 'data-customer'}, {name: 'unit', attr: 'data-unit'}, {name: "amount", attr: "data-amount"} ]
    };

    var activityList = new List('activity_list', options);
  }
}