import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import {toMoment} from '@fullcalendar/moment';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment';
import List from 'list.js'



import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "calendar", "users", "date", "view", "user_id", "team" ]

  connect() {
    var scrollTime = moment().format("HH") + ":00:00";

    var calendarEl = document.getElementById('calendar');
    var account = $('.account').data('account')
    var user_id = $('#user_id').val()
    var timezone = $('meta[name=tz_info]').attr("content");
    window.calendar = new Calendar(calendarEl, {
      plugins: [ interactionPlugin, timeGridPlugin, dayGridPlugin, momentPlugin, momentTimezonePlugin, bootstrapPlugin, resourceTimelinePlugin ],
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      themeSystem: 'bootstrap',
      defaultView: $('#view').val(),
      header: false,
      // {
      //   left: 'prev,next today',
      //   center: 'title',
      //   right: 'timeGridDay, timeGrid5Day, timeGridWeek, dayGridMonth' //resourceTimeline
      // },
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      droppbale: true,
      selectable: true,
      // eventStartEditable: true,
      // eventDurationEditable: true,
      // eventResourceEditable: true,
      allDaySlot: false,
      timeFormat: 'hh:mm',
      scrollTime: "07:00:00",
      nowIndicator: true,
      timeZone: timezone,
      columnHeaderFormat: {
        day: '2-digit',
        week: 'numeric'
      },

      longPressDelay: 200,
      weekNumbers: true,
      eventLimit: true, // allow "more" link when too many events
      height: 'parent',
      // timeFormat: 'HH:mm',
      slotLabelFormat: 'HH:mm',
      selectMirror: true,
      firstDay: 1, // 1 = monday
      defaultDate: $('#start').val(),
      eventColor: '#005b61',
      eventBorderColor: 'white',
      views: {
        timeGrid5Day: {
          type: 'timeGrid',
          weekends: false,
          duration: { days: 7 },
          buttonText: '5 day'
        },
      },
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
        // second: '2-digit'
      },
      eventSources: [
        {
          url: `/${account}/calendar_events.json`,
          method: 'GET',
          extraParams: function() {
            return {
              user_id: $('#user_id').val(),
              team_id: $('#team_id').val(),
              customer_id: $('#customer_id').val(),
              project_id: $('#project_id').val(),
              start: $('#start').val(),
              end: $('#end').val(),
          }}
        },
        {
          url: `/${account}/holidays.json`,
          method: 'GET',
          extraParams: function() {
            return {
              user_id: $('#user_id').val(),
              start: $('#start').val(),
              end: $('#end').val(),
          }}
        }
      ],
      // resourceOrder: 'name',
      // resources: {
      //   url: `/${account}/users.json`,
      //   method: 'GET',
      //   extraParams: function() {
      //     return {
      //       team_id: $('#team_id').val(),
      //       user_id: $('#user_id').val()
      //   }}
      // },

      select: function( info ) {
        if(info.view.type == 'resourceTimeline') {
          var user_id = [info.resource.id]
        } else {
          var user_id = $('#user_id').val()
        }

        if ($('#customer_id').val().length == 1) {
          var customer_id = $('#customer_id').val()[0]
        }
        if ($('#project_id').val().length == 1) {
          var project_id = $('#project_id').val()[0]
        }

        $.ajax({
          type: 'GET',
          url: '/' + account + '/events/new',
          data: {
            'event[from]': info.startStr,
            'event[to]': info.endStr,
            'event[event_type]': 'regular',
            'event[user_id]': user_id,
            'event[customer_id]': customer_id,
            'event[project_id]': project_id,
            // account_id: account,
            // status: "open",
          },
          dataType: "script",
        })
      },

      eventClick: function( info ) { 
        $.ajax({
          type: 'GET',
          url: '/' + account + '/events/' + info.event.id +'/edit', 
          dataType: 'script'
        })
      },

      eventDrop: function( info ) { 
        var data = new FormData;
        let start = toMoment(info.event.start, calendar); // calendar is required
        let end = toMoment(info.event.end, calendar); // calendar is required
        data.append("event[from]", start.format());
        data.append("event[to]", end.format());

        $.ajax({
          type: 'PUT',
          url: '/' + account + '/events/' + info.event.id,
          data: data,
          processData: false,
          contentType: false,
          credentials: "include",
          dataType: "json",
          headers: {
                  "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
           }
        })
      },

      eventResize: function( info) { 
        var data = new FormData;
        let start = toMoment(info.event.start, calendar); // calendar is required
        let end = toMoment(info.event.end, calendar); // calendar is required
        data.append("event[from]", start.format());
        data.append("event[to]", end.format());

        $.ajax({
          type: 'PUT',
          url: '/' + account + '/events/' + info.event.id,
          data: data,
          processData: false,
          contentType: false,
          credentials: "include",
          dataType: "json",
          headers: {
                  "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
           }
        })
      },

      datesRender: function(info) {
        // view, el
        let start = toMoment(info.view.currentStart, calendar);
        let end = toMoment(info.view.currentEnd, calendar);

        $('#start').val(start.format())
        $('#end').val(end.format())

      },

      viewSkeletonRender: function(info) {
        // view, el
        $('#view').val(info.view.type)
        if (calendar.view.type == 'timeGridDay') {
          $(this.viewTarget).text('Day')
        }

        if (calendar.view.type == 'timeGrid5Day') {
          $(this.viewTarget).text('5 day')
        }

        if (calendar.view.type == 'timeGridWeek') {
          $(this.viewTarget).text('Week')
        }

        if (calendar.view.type == 'dayGridMonth') {
          $(this.viewTarget).text('Month')
        }
      },

      eventRender: function(info) {
        var view = info.view
        var event = info.event
        var element = info.el
        // var title = element.find(".fc-title").val();
        if(view.type == 'timeGridDay' || view.type == 'timeGrid5Day' || view.type == 'timeGridWeek'){
          $(element).find(".fc-content").append("<div class='pb-1'><i class='fas fa-user mr-2'></i>"+event.extendedProps.responsible+"</div");
          $(element).find(".fc-content").append("<div><i class='fas fa-briefcase mr-2'></i>" + event.extendedProps.project_name + "</div>")
          $(element).find(".fc-content").append("<div class='pb-1'><i class='fas fa-tag mr-2'></i>"+event.extendedProps.activity_name+"</div");
          $(element).find(".fc-content").addClass('p-2');
        } 

        // if(view.type == 'month'){
        //   // element.find(".fc-time").replaceWith("<span>"+event.start.format('HH:mm')+"</span>");
        //   $(element).find(".fc-content").append("<div class='pb-1'><i class='fas fa-user mr-2'></i>"+event.extendedProps.responsible+"</div");
        //   $(element).find(".fc-content").append("<div><i class='fas fa-briefcase mr-2'></i>" + event.extendedProps.project_name + "</div>")
        // }


    },

    });
    calendar.render();
    this.setDate()

    // window.refresh = window.setInterval(function(){
    //   calendar.refetchEvents()
    // }, 10000);

    var $this = this
    if (this.hasTeamTarget){
      window.teamTarget = $($this.teamTarget).selectize({
        plugins: ['remove_button'],
        onBlur: function() {
          updateUsers()
        },
        onChange: function() {
          updateUsers()
        }
      });
    };


    if ($('#user_list').length) {
      var options = {
        valueNames: ['name']
      };

      var userList = new List('user_list', options);    

      // userList.on('searchComplete', function() {
      //   calendar.refetchEvents()
      // })
    }


    function updateUsers() {
      if ($this.hasTeamTarget){
        var team_ids = $($this.teamTarget).val()
      } else  {
        var team_ids = ''
      }

      if (team_ids.length) {
        var user_ids = $($this.user_idTargets).map(function() {
          return $(this).val();
        }).get();

        $(user_ids).each(function() {
          var user_id = this
          var user_element = $(`[data-user-id='${user_id}']`);
          var user_checkbox = $(`#user_id_${user_id}`);
          var user_team_ids = $(user_element).data('team-ids')

          $(user_team_ids).each(function(){
            var team_id = this
            if ($.inArray( `${team_id}`, team_ids ) != -1) {
              $(user_element).show();
              $(user_checkbox).attr('disabled', false);
              } else {
              $(user_element).hide();
              $(user_checkbox).attr('disabled', true);
             }
          })

          if (!$(user_team_ids).length) {
            $(user_element).hide();
            $(user_checkbox).attr('disabled', true);
          }
        })
      } else {
        $($this.user_idTargets).attr('disabled', false);
        $($this.user_idTargets).prop('checked', false);
        $('.current_user_id').find('input').prop('checked', true);
        $('.user_id').show();
      }
    // var users = $('#user_id').val(user_ids)

      $this.setUsers()
      calendar.refetchEvents()
    }
    if ($this.hasTeamTarget){
      updateUsers()
    }
  }

  disconnect() {
    calendar.destroy()
  }

  prev() {
    calendar.prev();
    this.setDate()
  }

  next() {
    calendar.next();
    this.setDate()
  }

  today() {
    calendar.today()
    this.setDate()
  }

  day() {
    calendar.changeView('timeGridDay')
    this.setDate()
  }

  fiveday() {
    calendar.changeView('timeGrid5Day')
    this.setDate()
  }

  week() {
    calendar.changeView('timeGridWeek')
    this.setDate()
  }

  month() {
    calendar.changeView('dayGridMonth')
    this.setDate()
  }

  setDate() {
    var date = calendar.view.title
    $(this.dateTarget).html(date)
    $('#view').val(calendar.view.type)

    if (calendar.view.type == 'timeGridDay') {
      $(this.viewTarget).text('Day')
    }

    if (calendar.view.type == 'timeGrid5Day') {
      $(this.viewTarget).text('5 day')
    }

    if (calendar.view.type == 'timeGridWeek') {
      $(this.viewTarget).text('Week')
    }

    if (calendar.view.type == 'dayGridMonth') {
      $(this.viewTarget).text('Month')
    }
  }


  openNav() {
    document.getElementById("sidenav").classList.add('open')
    document.getElementById("calendar_container").classList.add('open')
  }

  closeNav() {
    document.getElementById("sidenav").classList.remove('open')
    document.getElementById("calendar_container").classList.remove('open')
  }

  setUsers() {
    var array = $(this.user_idTargets).filter(":checked").map(function() {
      return $(this).val();}).get()

    var users = $('#user_id').val(array)

    calendar.refetchEvents()
  }

  disconnect() {
    if (this.hasTeamTarget) {
      $(this.teamTarget)[0].selectize.destroy();
    }

    // clearInterval(refresh)
    calendar.destroy()
  }

}
