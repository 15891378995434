import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template" ]


  openModal() {
    var id = $(this.templateTarget).data('id')
    $('#copy_countdown_template_id').val(id)
    $('#copy_countdown').modal('toggle')
  }
}