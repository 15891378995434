import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "search", "result_list"]

  connect() {
    var account = $('.account').data('account');
    // var loggable_type = $(this.loggableTarget).data('type')
    // var loggable_id = $(this.loggableTarget).data('id')
    // var request = $.ajax ({
    //   url: `/${account}/search`,
    //   dataType: 'script',
    //   method: "GET"
    // })
    
    $('#search_modal').on('shown.bs.modal', function () {
      $('#q').trigger('focus');
    });


  }
}