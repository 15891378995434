import { Controller } from "@hotwired/stimulus"
import "jquery"
import "datatables.net"
// require( 'datatables.net-colreorder-bs4' );
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  static targets = [ "all", "event" ]

  connect() {
    // var ActivityListOptions = {
    //   valueNames: [ 'customer', 'customer-activity', 'project', 'project-activity']
    // };

    // var activityList = new List('activity-list', ActivityListOptions);
    
    window.datatable = $('.datatable').DataTable({
      paging: false,
      info: false,
      searching: false,
      scrollY: "calc(100vh - 300px)",
      scrollX: true,
      scrollCollapse: true,
      // colReorder: true
      // fixedColumns: {
      //   leftColumns: 3
      // }
    });

  }

  toggleVisibility(){
    var column = datatable.column( $(event.target).attr('data-column') );
    // console.log(event.target)
    // console.log($(event.target).attr('data-column'))
    // console.log(column)
 
        // Toggle the visibility
    column.visible( ! column.visible() );
  }

  disconnect() {
    datatable.destroy();
  }
}