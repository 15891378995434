import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import {toMoment} from '@fullcalendar/moment';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "calendar", "users", "date", "view" ]

  connect() {
    var scrollTime = moment().format("HH") + ":00:00";

    var calendarEl = document.getElementById('mobile-calendar');
    var account = $('.account').data('account')
    var user_ids = $('#user_ids').val()

    window.calendar = new Calendar(calendarEl, {
      plugins: [ interactionPlugin, timeGridPlugin, dayGridPlugin, momentPlugin, bootstrapPlugin, resourceTimelinePlugin ],
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      themeSystem: 'bootstrap',
      defaultView: $('#view').val(),
      header: false,
      // {
      //   left: 'prev,next today',
      //   center: 'title',
      //   right: 'timeGridDay, timeGrid5Day, timeGridWeek, dayGridMonth' //resourceTimeline
      // },
      navLinks: true, // can click day/week names to navigate views
      editable: true,
      droppbale: true,
      selectable: true,
      // eventStartEditable: true,
      // eventDurationEditable: true,
      // eventResourceEditable: true,
      allDaySlot: false,
      timeFormat: 'hh:mm',
      scrollTime: scrollTime,
      nowIndicator: true,
      timeZone: 'utz',

      // longPressDelay: 200,
      weekNumbers: true,
      eventLimit: true, // allow "more" link when too many events
      height: 'parent',
      // timeFormat: 'HH:mm',
      slotLabelFormat: 'HH:mm',
      selectMirror: true,
      firstDay: 1, // 1 = monday
      defaultDate: $('#start').val(),
      eventColor: '#007F8D',
      eventBorderColor: 'white',
      views: {
        timeGrid5Day: {
          type: 'timeGrid',
          weekends: false,
          duration: { days: 7 },
          buttonText: '5 day'
        },
      },
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
        // second: '2-digit'
      },
      eventSources: [
        {
          url: `/${account}/admin/calendar_events.json`,
          method: 'GET',
          extraParams: function() {
            return {
              user_ids: $('#user_ids').val(),
              team_ids: $('#team_ids').val(),
              customer_ids: $('#customer_ids').val(),
              project_ids: $('#project_ids').val(),
              start: $('#start').val(),
              end: $('#end').val(),
          }}
        },
        {
          url: `/${account}/admin/holidays.json`,
          method: 'GET',
          extraParams: function() {
            return {
              user_ids: $('#user_ids').val(),
              start: $('#start').val(),
              end: $('#end').val(),
          }}
        }
      ],
      resourceOrder: 'name',
      resources: {
        url: `/${account}/admin/users.json`,
        method: 'GET',
        extraParams: function() {
          return {
            team_ids: $('#team_ids').val(),
            user_ids: $('#user_ids').val()
        }}
      },

      select: function( info ) {
        if(info.view.type == 'resourceTimeline') {
          var user_ids = [info.resource.id]
        } else {
          var user_ids = $('#user_ids').val()
        }

        $.ajax({
          type: 'GET',
          url: '/' + account + '/admin/events/new',
          data: {
            'event[from]': info.startStr,
            'event[to]': info.endStr,
            'event[event_type]': 'regular',
            'event[user_ids]': user_ids,
            // account_id: account,
            // status: "open",
          },
          dataType: "script",
        })
      },

      eventClick: function( info ) { 
        $.ajax({
          type: 'GET',
          url: '/' + account + '/admin/events/' + info.event.id +'/edit', 
          dataType: 'script'
        })
      },

      eventDrop: function( info ) { 
        var data = new FormData;
        let start = toMoment(info.event.start, calendar); // calendar is required
        let end = toMoment(info.event.end, calendar); // calendar is required
        data.append("event[from]", start.format());
        data.append("event[to]", end.format());

        $.ajax({
          type: 'PUT',
          url: '/' + account + '/admin/events/' + info.event.id,
          data: data,
          processData: false,
          contentType: false,
          credentials: "include",
          dataType: "json",
          headers: {
                  "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
           }
        })
      },

      eventResize: function( info) { 
        var data = new FormData;
        let start = toMoment(info.event.start, calendar); // calendar is required
        let end = toMoment(info.event.end, calendar); // calendar is required
        data.append("event[from]", start.format());
        data.append("event[to]", end.format());

        $.ajax({
          type: 'PUT',
          url: '/' + account + '/admin/events/' + info.event.id,
          data: data,
          processData: false,
          contentType: false,
          credentials: "include",
          dataType: "json",
          headers: {
                  "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
           }
        })
      },

      datesRender: function(info) {
        // view, el
        let start = toMoment(info.view.currentStart, calendar);
        let end = toMoment(info.view.currentEnd, calendar);

        $('#start').val(start.format())
        $('#end').val(end.format())

      },

      viewSkeletonRender: function(info) {
        // view, el
        $('#view').val(info.view.type)
      },

      eventRender: function(info) {
        var view = info.view
        var event = info.event
        var element = info.el
        // var title = element.find(".fc-title").val();
        if(view.type !== 'dayGridMonth' || view.type !== 'resourceTimeLine'){
          if (event.extendedProps.customer_name !== null) {
            $(element).find(".fc-content").append("<div class=' pb-1'>" + event.extendedProps.customer_name + "</div><div>" + event.extendedProps.project_name + "</div>")
          }
          $(element).find(".fc-content").append("<div class=' pb-1'>"+event.extendedProps.responsible+"</small></div");
        }

        // if(view.type == 'month'){
        //   element.find(".fc-time").replaceWith("<span>"+event.start.format('HH:mm')+"</span>");
        //   element.find(".fc-title").html("<b>"+event.title+"</b>");
        // }

        function project(event) {
          if(event.extendedProps.project_name != "null"){
            return event.extendedProps.project_name
          } 
          else {
            return ""
          }
        }
    },

    });
    calendar.render();
    this.setDate()
  }

  disconnect() {
    calendar.destroy()
  }

  prev() {
    calendar.prev();
    this.setDate()
  }

  next() {
    calendar.next();
    this.setDate()
  }

  today() {
    calendar.today()
    this.setDate()
  }

  day() {
    calendar.changeView('timeGridDay')
    this.setDate()
  }

  fiveday() {
    calendar.changeView('timeGrid5Day')
    this.setDate()
  }

  week() {
    calendar.changeView('timeGridWeek')
    this.setDate()
  }

  month() {
    calendar.changeView('dayGridMonth')
    this.setDate()
  }

  setDate() {
    var date = calendar.view.title
    $(this.dateTargets).html(date)
    $('#view').val(calendar.view.type)

    if (calendar.view.type == 'timeGridDay') {
      $(this.viewTargets).each(function() {
        $(this).text('Day')
      })
    }

    if (calendar.view.type == 'timeGrid5Day') {
      $(this.viewTargets).text('5 day')
    }

    if (calendar.view.type == 'timeGridWeek') {
      $(this.viewTargets).text('Week')
    }

    if (calendar.view.type == 'dayGridMonth') {
      $(this.viewTargets).text('Month')
    }

    console.log(calendar.view.type)

  }


}
