import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "check_all", "check_event", "customer_id", "event", "amount" ]

  connect() {

    window.datatable = $('.event-table').DataTable({
      paging: false,
      info: false,
      searching: false,
      scrollY: "calc(100vh - 350px)",
      scrollX: true,
      scrollCollapse: true,
      // colReorder: true
      // fixedColumns: {
      //   leftColumns: 3
      // }

    });

    var $this = this

    this.getEvents()

    datatable.on( 'draw', function () {
      $this.getEvents()
    });

  }


  getEvents(){
    var all_ids = $('.event-checkbox:checkbox').map(function() {return $(this).val();}).get()
    var checked_ids = $('.event-checkbox:checkbox').filter(':checked').map(function() {return $(this).val();}).get()

    if(checked_ids.length == 0) {
      var ids = all_ids
    } else {
      var ids = checked_ids
    }

    var html = "";

    $.each(ids, function(index, value) {
      html += "<input class='checked-ids' type='hidden' name='ids[]' value=" + value + ">";
     })

    $(".ids").each(function(){
      $(this).html( html )
    })

    var account = $('.account').data('account');
    var customer_id = $('#customer_id').data('id')
    var project_id = $('#customer_id').data('project-id')
    var sub_projects = $('#customer_id').data('sub-projects')
    var from_date = $('#customer_id').data('from')
    var to_date = $('#customer_id').data('to')

    var request = $.ajax ({
      url: `/${account}/billing/customers/${customer_id}/events`,
      dataType: 'script',
      method: "GET",
      data: {ids: ids,
        from: from_date,
        to: to_date,
        project_id: project_id,
        include_sub_projects: sub_projects
      }
    })
  }



  toggleAll() {
    if ($(this.check_allTarget).prop('checked') == false) {
      $(this.check_eventTargets).prop('checked', false)
      this.toggle()
    }
    if ($(this.check_allTarget).prop('checked') == true) {
      $(this.check_eventTargets).prop('checked', true)
      this.toggle()
    }
    this.getEvents();
  }

  toggle() {
    $(this.check_eventTargets).filter(":not(:checked)").map(function() {
      $(this).closest('tr').removeClass('table-primary')
    })

    $(this.check_eventTargets).filter(":checked").map(function() {
      $(this).closest('tr').addClass('table-primary')
    })
    this.getEvents();
  }

  highlight() {
    $(this.check_eventTargets).filter(":checked").map(function() {
      $(this).closest('tr').addClass('table-active')
    })
  }

}