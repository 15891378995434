import { Controller } from "@hotwired/stimulus"
import "jquery"


export default class extends Controller {
  static targets = [ "event_type_selector", "regular_event", "away_event", "bookable_event", "privacy_selector", "privacy", "nonaway", "allday_toggle", "pricing" ]

  connect() {

    $('.popover-dismiss').popover({
      trigger: 'focus'
    })

    $('.attachment__name').dblclick(function(event) {
      var url = $(event.target).closest('figure[data-trix-attachment]').data('trix-attachment').url
      window.open(url, '_blank');
    })

    // this.outputTarget.textContent = 'Hello, Stimulus!'
    if ($('#edit_invoice').length) {
      $('#edit_invoice_param').val('true')
    }


    // this.setPrivacy();

    // // console.log($(this.event_type_selectorTarget).val())
    // // check for initial value
    // if ($(this.event_type_selectorTarget).val() == "regular") {
    //   $(this.regular_eventTarget).show()

    //   if (this.hasAway_eventTarget) { 
    //     $(this.away_eventTarget).hide()
    //    }

    //   if (this.hasBookableEventTarget) {
    //     $(this.bookable_eventTarget).hide()
    //   }

    //   if (this.hasPrivacyTarget) {
    //     if ($(this.privacy_selectorTarget).val() == "private") {
    //       $(this.privacyTarget).show()
    //     } else {
    //       $(this.privacyTarget).hide()
    //     }
    //   }

    // }

    // if ($(this.event_type_selectorTarget).val() == "away") {
    //   $(this.nonawayTargets).hide()
    //   // $(this.regular_eventTarget).hide()
    //   // $(this.away_eventTarget).show()
    //   // $(this.bookable_eventTarget).hide()
    // }

    // if ($(this.event_type_selectorTarget).val() == "bookable") {
    //   $(this.regular_eventTarget).hide()
    //   $(this.away_eventTarget).hide()
    //   $(this.bookable_eventTarget).show()
    // }
    // if ($(this.custom_priceTarget).prop)

    $('.event-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
    });

    // var user_ids = $('#user_ids').val()
    // var customer_ids = $('#customer_ids').val()
    // var project_ids = $('#project_ids').val()

    // $('#user_ids')[0].selectize.destroy();
    // $('#user_ids').siblings('div.selectize').remove();

    // $('#customer_ids')[0].selectize.destroy();
    // $('#customer_ids').siblings('div.selectize').remove();


    // $('#project_ids')[0].selectize.destroy();
    // $('#project_ids').siblings('div.selectize').remove();

    // $('#user_ids').val(user_ids)
    // $('#customer_ids').val(customer_ids)
    // $('#project_ids').val(project_ids)

    // $('#user_ids').selectize({
    //   plugins: ['remove_button'],
    // });
    // $('#customer_ids').selectize({
    //   plugins: ['remove_button'],
    // });
    // $('#project_ids').selectize({
    //   plugins: ['remove_button'],
    // });
    $('.user-mention').attr('target', "_blank")

    // var modal_scrollHeight = $('.modal-body').prop('scrollHeight');


    $('.modal-body').scroll(function(){
      var modal_scrollTop = $('.modal-body').scrollTop();
      var modal_innerHeight = $('.modal-body').innerHeight();
      $('#event_modal_height').val(modal_scrollTop)
    })

  }

  setType() {
    if ($(this.event_type_selectorTarget).val() == "regular") {
      $(this.regular_eventTarget).show()
      $(this.away_eventTarget).hide()
      $(this.bookable_eventTarget).hide()
    }

    if ($(this.event_type_selectorTarget).val() == "away") {
      // console.log(this.nonawayTargets)
      $(this.nonawayTargets).hide()
      $(this.regular_eventTarget).hide()
      $(this.away_eventTarget).show()
      $(this.bookable_eventTarget).hide()
    }

    // if ($(this.event_type_selectorTarget).val() == "bookable") {
    //   $(this.regular_eventTarget).hide()
    //   $(this.away_eventTarget).hide()
    //   $(this.bookable_eventTarget).show()
    // }
  }

  setPrivacy() {
    // console.log('got it')
    // if ($(this.privacy_selectorTarget).is(':checked')) {
    //   $(this.pricingTargets).hide()
    // } else {
    //    $(this.pricingTargets).show()
    // }

    // console.log($(this.privacy_selectorTarget).val())
    // if ($(this.privacy_selectorTarget).val() == "Private") {
    //   $(this.privacyTarget).show()
    // } 
    // if ($(this.privacy_selectorTarget).val() == "Normal") {
    //   $(this.privacyTarget).hide()
    // }
  }

  disconnect() {
    // $('#user_ids')[0].selectize.destroy();
    // $('.selectize').remove()
    //    $('.selectize').selectize({
    //   plugins: ['remove_button']
    // });

    // $('.selectize').each(function() {
    //   console.log(this)
    //   $(this)[0].selectize.destroy()
    //   // if (this.selectize) {
    //       // this.selectize.destroy();
    //   // }
    // });
  }


  // selectActivityPrice() {
  //   $(this.custom_priceTarget).hide()
  //   $(this.activity_priceTarget).show()
  // }
}