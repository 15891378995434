import { Controller } from "stimulus"
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


 export default class extends Controller {
  static targets = [ "activities", "projectActivities" ]

  connect() {
    // Customer table
    var activities = []

	  for (var option of this.activitiesTarget.options) {
	    if (option.selected) {
	      activities.push(option.value);
	    }
	  }

    var valueNames = [ 'customer1']

    activities.forEach(function (activity) {
    	var attr = 'data-' + activity
    	valueNames.push({name: activity, attr: attr})
		});

 		var options = {
			valueNames: valueNames
  	};

    var customerList = new List('customer-activities', options);

    // Project Table

    var pactivities = []

    for (var option of this.projectActivitiesTarget.options) {
      if (option.selected) {
        pactivities.push(option.value);
      }
    }


    var projectNames = [ 'customer', 'project']

    pactivities.forEach(function (activity) {
    	var attr = 'data-' + activity
    	projectNames.push({name: activity, attr: attr})
		});

 		var projectOptions = {
			valueNames: projectNames
  	};

    var projectList = new List('project-activities', projectOptions);
  }
} 