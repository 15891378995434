import { Controller } from "@hotwired/stimulus"
import 'jquery'

export default class extends Controller {
  static targets = [ "event", "user", 'users', "search_field", "selected_user" , 'is_client', 'invite' ]

  connect() {
    $('#user_results').click(function(e){
      e.preventDefault();
      e.stopPropagation();
    })
    // $('.modal').click(function(){
    //   $('#user_results').hide();
    // })
  }

  search() {
    $('#user_results').show();
    // $('#user_select_submit').click()


    var account = $('.account').data('account');
    var q = $(this.search_fieldTarget).val();
    var event_id = $(this.eventTarget).data('event-id')
    var excluded_ids = $(this.selected_userTargets).map(function() {
      return $(this).data('id');}).get()

    var request = $.ajax ({
      url: `/${account}/users/select_users?q=${q}&event_id=${event_id}`,
      dataType: 'script',
      method: "get",
      data: {
        'excluded_ids': excluded_ids
      }
    })

    // if (this.validateEmail($(this.search_fieldTarget).val())) {
    //   $('#invite_user').show();
    //   $('#invite_email').text($(this.search_fieldTarget).val());
    // }
    
    // request.done(function(data) {
    //   // console.log(JSON.stringify(data))
    //   var name = data.name
    //   var projects = data.projects
    //   $this.setCustomer(name, projects)
    // })

  }

 // validateEmail(email) {
 //    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 //    return re.test(String(email).toLowerCase());
 //  }


  select() {
    console.log('click')
    console.log('user_ids', $(this.usersTarget).data('user-ids'))
    var account = $('.account').data('account');

    var user_ids = []
    var user_id = $(event.target).closest('.list-group-item').data('id')
    user_ids.push(user_id)
    var event_users_ids = $(this.usersTarget).data('user-ids')
    
    $(event_users_ids).each(function() {
      event_users_ids.push(this)
    })

    var new_email = $(event.target).closest('.list-group-item').data('email')

    var event_id = $(this.eventTarget).data('event-id')
    // var current_ids = $(".event_users").
    $('#user_results').hide();
    $(this.search_fieldTarget).val("");

    var request = $.ajax ({
      url: `/${account}/users/select_user?user_id=${user_id}&event_id=${event_id}&new_email=${new_email}`,
      dataType: 'script',
      method: "get"
      // data: {
      //   'event[user_ids]': event_users_ids
      // }
    })

    // request.done(function(data) {
    //   // console.log(JSON.stringify(data))
    //   var name = data.name
    //   var projects = data.projects
    //   $this.setCustomer(name, projects)
    // })
  }

  remove() {
    console.log('remove')
    $(event.target).closest('.list-group-item').remove();
  }

  closeSearch() {
    $('#user_results').hide();
  }

  toggleIsClient() {
    console.log(event.target)
    console.log($('#organizer_is_a_client'))
    console.log($('#organizer_is_a_client').prop('checked'))
    if ($('#organizer_is_a_client').prop('checked') == true) {
      $(this.is_clientTarget).show()
    } else {
      $(this.is_clientTarget).hide()
    }
  }

  toggleInvite() {
    if ($(event.target).prop('checked') == true) {
      $(this.inviteTarget).show()
    } else {
      $(this.inviteTarget).hide()
    }
  }

  selectCustomer() {

  }

  createCustomer() {

  }


}

























