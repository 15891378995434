// Entry point for the build script in your package.json
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// this
// require("controllers")
// require("@rails/ujs").start();


// // becomes
// import "./controllers"
// import Rails from “@rails/ujs”
// Rails.starts();


// require("@rails/ujs").start()
// require("@rails/activestorage").start()

import "@hotwired/turbo-rails"
// import "@rails/request.js"


// require("channels")
// require("trix")
// require("@rails/actiontext")
// import "@hotwired/turbo-rails"
// require('highcharts')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import flatpickr from "flatpickr"
// import jQuery from “@rails/ujs”

// global.$ = require("jquery");
// require("jquery")
// // , require("jquery-ui")
// require("jquery-ui")


import "./jquery"


// require( 'datatables.net' )( window, $ );

// require ("popper.js") // apparently have to put css here as well
// import popper from "popper.js/dist/popper";
// import bootstrap from "bootstrap"
import * as bootstrap from "bootstrap"
// require ("flatpickr/dist/flatpickr.css") // apparently have to put css here as well
// import Chartkick from "chartkick";
// window.Chartkick = Chartkick;
// import Chart from 'chart.js'
import "chartkick"
import "chartkick/highcharts"

// require("chartkick")
// require("chart.js")
// import('chartkick/highcharts')
// require("chartkick").use(require("highcharts"))
// window.Highcharts = require('highcharts');
// import Highcharts from 'highcharts'

// import "chartkick"
import Highcharts from "highcharts"
window.Highcharts = Highcharts
// import "highcharts/modules/exporting"
import enableExporting from 'highcharts/modules/exporting';

// import "highcharts/modules/offline-exporting"
// import "highcharts/modules/export-data"
import addOfflineExportingModule from 'highcharts/modules/offline-exporting';
import addExportData from 'highcharts/modules/export-data';
import variwide from 'highcharts/modules/variwide'
// import enableExporting from 'highcharts/modules/exporting';

enableExporting(Highcharts);
addOfflineExportingModule(Highcharts);
addExportData(Highcharts);
variwide(Highcharts);
// import "chartkick/highcharts"

// TODO: Check highcharts

// require('highcharts/modules/boost')(Highcharts); 

import ChartDataLabels from 'chartjs-plugin-datalabels';


// import floatthead from 'floatthead'
import List from 'list.js'
import selectize from '@selectize/selectize'
import select2 from 'select2';
// import 'select2/dist/css/select2.css';
select2();

import "./controllers"



document.addEventListener('turbo:load', () => {



  $('[data-toggle="tooltip"]').not('.no-tooltip-hover').tooltip({
    trigger: "hover"
  })
  // $(function () {
    // $('[data-toggle="tooltip"]').tooltip()
  // })

  var EventListOptions = {
    valueNames: [ 'name', {name: 'from', attr: 'data-from-timestamp'}, {name: 'date', attr: 'data-date'}, 'responsible', 'customer', 'project', 'activity', 'project_hierarchy', 'activity_category', 'invoice_description', 'total_amount', 'status', 'unit_price', 'unit', 'quantity', 'hours', 'invoice']
  };

  var eventList = new List('event-list', EventListOptions);

});


