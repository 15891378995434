import flatpickr from "flatpickr"
// import "flatpickr/dist/themes/dark.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "simple", "time" ]

  initialize() {
    const altFormat = 'd.m.Y'


    if (this.hasSimpleTarget) {
      var simpletarget = flatpickr(this.simpleTarget, {
        altInput: true,
        allowInput: true,
        altFormat,
        altInputClass: 'form-control input bg-white',
        dateFormat: "Y-m-d",
        focus: false, 
        locale: {
            firstDayOfWeek: 1 // start week on Monday
        },
      })

      simpletarget._input.addEventListener('input', (event) => {
        const value = simpletarget._input.value
        const parsedDate = simpletarget.parseDate(value, altFormat)
        const formattedDate = simpletarget.formatDate(parsedDate, altFormat)
        if(value === formattedDate) simpletarget.setDate(value, true, altFormat)
      }, true)


    }



    if (this.hasTimeTarget) {
        flatpickr(this.timeTarget, {
        altInput: true,
        enableTime: true,
        time_24hr: true,
        altFormat: "F j, Y H:i",
        dateFormat: "Y-m-d H:i",
        focus: false,
        locale: {
            firstDayOfWeek: 1 // start week on Monday
        },
      })
    }
  }

  disconnect() {
    if (this.hasSimpleTarget){
      this.simpleTarget._flatpickr.destroy();
    }
    if (this.hasTimeTarget){
      this.timeTarget._flatpickr.destroy();
    }
  }
}
