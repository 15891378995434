import { Controller } from "@hotwired/stimulus"
import "jquery"

export default class extends Controller {
  static targets = [ "input", "selectize", "from", "to", "customer", "project", "activity", "unit_price", "unit", "quantity", "total_amount", "custom", "calculated", "changed", "invoiceable", "price_id", "locked", "lock_toggle", 'local', 'customer_container', 'project_container', 'billing'  ]

  connect() {

    var $this = this

    const altFormat = 'd.m.Y H:i'


    if ($this.hasFromTarget) {
      global.dateFrom = flatpickr($this.fromTarget, {
        altInput: true,
        allowInput: true,
        altFormat,
        dateFormat: "Y-m-dTH:i",
        altInputClass: 'form-control input bg-white',
        enableTime: true,
        time_24hr: true,
        locale: {
            firstDayOfWeek: 1 // start week on Monday
        },
        maxDate: $this.toTarget.value,
        onChange:  function(selectedDates, dateStr, instance) {
          dateTo.set('minDate', dateStr);
          $($this.changedTarget).val(true);
          $this.getUnitPrice();
        },
      });
    }

    if ($this.hasToTarget) {
      global.dateTo = flatpickr($this.toTarget, {
        altInput: true,
        allowInput: true,
        altFormat,
        altInputClass: 'form-control input bg-white',
        dateFormat: "Y-m-dTH:i",
        enableTime: true,
        time_24hr: true,
        locale: {
            firstDayOfWeek: 1 // start week on Monday
        },
        minDate: $this.fromTarget.value,
        onChange:  function(selectedDates, dateStr, instance) {
          dateFrom.set('maxDate', dateStr)
          $($this.changedTarget).val(true);
          $this.getUnitPrice();
        },
      });
    }

    dateFrom._input.addEventListener('input', (event) => {
      const value = dateFrom._input.value
      const parsedDate = dateFrom.parseDate(value, altFormat)
      const formattedDate = dateFrom.formatDate(parsedDate, altFormat)
      if(value === formattedDate) dateFrom.setDate(value, true, altFormat)
    }, true)


    dateTo._input.addEventListener('input', (event) => {
      const value = dateTo._input.value
      const parsedDate = dateTo.parseDate(value, altFormat)
      const formattedDate = dateTo.formatDate(parsedDate, altFormat)
      if(value === formattedDate) dateTo.setDate(value, true, altFormat)
    }, true)


    if (this.hasSelectizeTarget){
      window.selectizeTarget = $(this.selectizeTarget).selectize({
        plugins: ['remove_button'],
        onBlur: function() {
          $($this.changedTarget).val(true)
        }
      });
    }
    window.project = $('.project-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'name', 'optgroup'],
      valueField: 'id',
      labelField: 'name',
      onChange: function() {
        $($this.changedTarget).val(true);
      }
    })

   window.customer =  $('.customer-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
      onChange: function(value, item){
        $($this.changedTarget).val(true);
        $this.getUnitPrice();
        // $('#event_submit').click();
        var account = $('.account').data('account');
        var request = $.ajax ({
          url: `/${account}/customers/${value}`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          var name = data.name
          var projects = data.projects
          $this.setCustomer(name, projects)
        })
      },
      onClear: function(value) {
        $($this.changedTarget).val(true);
        $this.getUnitPrice();

        var account = $('.account').data('account');
        var request = $.ajax ({
          url: `/${account}/projects/`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          var name = "Internal"
          var projects = data.projects
          $this.setCustomer(name, projects)
        })
      },

    });

    window.activity =  $('.activity-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
      onChange: function(value) {
        $($this.changedTarget).val(true);
        // $this.setType();
        $this.getUnitPrice();
      }
    });

    $('.attachment__name').dblclick(function(event) {
      var url = $(event.target).closest('figure[data-trix-attachment]').data('trix-attachment').url
      window.open(url, '_blank');
    })

    if ( $($this.invoiceableTarget).val() == 'false' || $($this.lockedTarget).val() == 'true') {
      $this.lock()
      // $($this.inputTargets).prop('disabled', true)
      // $this.customerTarget.selectize.disable()
      // $this.projectTarget.selectize.disable()
      // $this.activityTarget.selectize.disable()
      // $this.selectizeTarget.selectize.disable()
      // $this.fromTarget._flatpickr.destroy();
      // $this.toTarget._flatpickr.destroy();
    } else {
      $this.unlock()
    }

    // if ($($this.unitTarget).val() == "hours"){
    //   $($this.quantityTarget).prop('readonly', true)
    // } else {
      if ($($this.lockedTarget).val() == 'false' || $($this.invoiceableTarget).val() == 'false') {
        $($this.quantityTarget).prop('readonly', false)
      }
    // }

    // this.setType();
    // $('#event_modal').on('hide.bs.modal', function (e) {
    //   if (event_changed == true) {
    //     if(!confirm("Are you sure you want to close? This will not save your changes.")) return false;
    //   }
    // })
  }

  updateEvent() {
    // Needs to get selectize + flatpickr changes to
    $(this.changedTarget).val(true)
  }

  saveEvent(){
    $(this.changedTarget).val(false)
    $('#event_submit').click();
  }

  formSubmit() {
    $(this.changedTarget).val(false)
    $('#event_modal').modal('hide')
  }

  setType() {
    var $this = this
    var account = $('.account').data('account');
    var activity_id = this.activityTarget.value 
    var request = $.ajax ({
      url: `/${account}/prices/search.json`,
      data: {
        activity_id: activity_id
      },
      dataType: 'JSON',
      method: "GET"
    })

    request.done(function(data) {
      var type = data.type

      if (type == 'internal') {
        $($this.customer_containerTarget).hide()

        $($this.billingTarget).hide()
        $($this.unit_priceTarget).val(0)
        $($this.unitTarget).val('hours')
        $($this.projectTarget).prop('required', true)
        $($this.project_containerTarget).show()
        $this.customerTarget.selectize.clear()

        var request = $.ajax ({
          url: `/${account}/projects/`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          var name = "Internal"
          var projects = data.projects
          $this.setCustomer(name, projects)
        })

      }

      if (type == "billable") {
        $($this.customer_containerTarget).show()
        $($this.billingTarget).show()
        $($this.project_containerTarget).show()
        $($this.projectTarget).prop('required', true)
      }


      if (type == "vacation" || type == "time_off") {
        $($this.customer_containerTarget).hide()
        // $($this.project_containerTarget).hide()
        $this.projectTarget.selectize.clear()
        $this.customerTarget.selectize.clear()
        $($this.projectTarget).prop('required', false)
        $($this.billingTarget).hide()
        $($this.unit_priceTarget).val(0)
        $($this.unitTarget).val('hours')
      }
    })

  }

  lock() {
    var $this = this
    $(this.lockedTarget).val(true)
    $($this.inputTargets).prop('readonly', true)
    $this.customerTarget.selectize.lock()
    $this.projectTarget.selectize.lock()
    $this.activityTarget.selectize.lock()
    $this.selectizeTarget.selectize.lock()

    $(this.fromTarget).prop('readonly', true)
    $(this.toTarget).prop('readonly', true)

    // if ($($this.unitTarget) == "hours") {
    //   $($this.quantityTarget).prop('readonly', true)
    // }

    $this.toggleUnitSelect()

    $($this.lock_toggleTarget).replaceWith('<button class="btn btn-outline-primary border-0" data-action="event#unlock" data-target="event.lock_toggle"><i class="fas fa-lock"></i></button>')
    $($this.total_amountTarget).prop('readonly', true)

    if (dateFrom.length) {
      dateFrom._input.setAttribute('disabled', 'disabled')
      dateTo._input.setAttribute('disabled', 'disabled')
    }
  }

  unlock() {
    var $this = this
    $(this.lockedTarget).val(false)
    $($this.inputTargets).prop('readonly', false)
    $this.customerTarget.selectize.unlock()
    $this.projectTarget.selectize.unlock()
    $this.activityTarget.selectize.unlock()
    $this.selectizeTarget.selectize.unlock()
    dateFrom._input.removeAttribute('disabled')
    dateTo._input.removeAttribute('disabled')

    $(this.fromTarget).prop('readonly', false)
    $(this.toTarget).prop('readonly', false)

    $this.toggleUnitSelect()

    if ($this.hasLock_toggleTarget){
      $($this.lock_toggleTarget).replaceWith('<button class="btn btn-outline-primary border-0" data-action="event#lock" data-target="event.lock_toggle"><i class="fas fa-lock-open"></i></button>')
    }
    $($this.total_amountTarget).prop('readonly', true)
  }

  toggleUnitSelect() {  
    var $this = this

    if ($($this.lockedTarget).val() == 'true') {
        $($this.unitTarget).addClass('locked')
        $($this.fromTarget).siblings('input').addClass('locked')
        $($this.toTarget).siblings('input').addClass('locked')
        $(dateTo).addClass('locked')
        $(dateFrom).addClass('locked')
      } else {
        $($this.unitTarget).removeClass('locked')
      }

    $($this.unitTarget).on('mousedown', function(e) {
      if ($($this.lockedTarget).val() == 'true') {
        e.preventDefault();
        this.blur();
        window.focus();
      }
    })

    $($this.fromTarget).siblings('input').first().on('mousedown', function(e) {
      if ($($this.lockedTarget).val() == 'true') {
        e.preventDefault();
        e.stopPropagation();
        this.blur();
        window.focus();
      }
    })

    $($this.fromTarget).on('mousedown', function(e) {
      if ($($this.lockedTarget).val() == 'true') {
        e.preventDefault();
        e.stopPropagation();
        this.blur();
        window.focus();
      }
    })


    $(dateFrom).on('mousedown', function(e) {
      if ($($this.lockedTarget).val() == 'true') {
        e.stopPropagation();
        e.preventDefault();
        this.blur();
        window.focus();
      }
    })
  }

  closeModal() {
    if (this.hasChangedTarget){
      if ( $(this.changedTarget).val() == 'true') {
        if(!confirm("Are you sure you want to close? This will not save your changes.")){
        return false;
        } else {
        $(this.projectTarget)[0].selectize.destroy()
        if (this.hasCustomerTarget){
          $(this.customerTarget)[0].selectize.destroy()
        }
        $(this.activityTarget)[0].selectize.destroy()
        $('#event_modal').modal('hide')
        }

      } else {
        $(this.projectTarget)[0].selectize.destroy()
        if (this.hasCustomerTarget){
          $(this.customerTarget)[0].selectize.destroy()
        }
        $(this.activityTarget)[0].selectize.destroy()
        $('#event_modal').modal('hide')

      }
    } else {
        $(this.projectTarget)[0].selectize.destroy()
        if (this.hasCustomerTarget){
          $(this.customerTarget)[0].selectize.destroy()
        }
        $(this.activityTarget)[0].selectize.destroy()
      $('#event_modal').modal('hide')

    }
  }

// grfwedwrergvfc
  selectCustomPrice() {
    $(this.unit_priceTarget).prop('readonly', false)
    // $(this.quantityTarget).prop('readonly', false)
    $(this.unitTarget).attr('readonly', false)
  }

  selectCalculatePrice() {
    $(this.unit_priceTarget).prop('readonly', true)
    // $(this.quantityTarget).prop('readonly', true)
    $(this.unitTarget).attr('readonly', true)
    this.getUnitPrice();
  }


  getUnitPrice() {
    var $this = this
    var account = $('.account').data('account');
    var customer_id = $(this.customerTarget).val()
    var project_id = $(this.projectTarget).val()
    var activity_id = $(this.activityTarget).val()

    var request = $.ajax ({
      url: `/${account}/prices/search.json`,
      data: {
        activity_id: activity_id,
        customer_id: customer_id,
        project_id: project_id
      },
      dataType: 'JSON',
      method: "GET"
    })
    request.done(function(data) {
      $($this.unit_priceTarget).val(data.price)
      $('#event_price_id').val(data.price_id)

      if ($($this.unitTarget).val() == "hours"){
        // $($this.calculatedTarget).prop('checked') == true
        // $($this.quantityTarget).prop('readonly', true)

        if (data.unit.length != 0 ) {
          if (data.unit != undefined ) {
            $($this.unitTarget).val(data.unit)
          }
        }
        $this.updatePrice();
      } else {
        if($($this.lockedTarget).val() == "false") {

          $($this.quantityTarget).prop('readonly', false)
            if (data.unit.length != 0 ) {
              if (data.unit != undefined ) {
                $($this.unitTarget).val(data.unit)
              }
            }
          $this.updatePrice();  
        }
      }
    })
  }

  updateToDate() {
    const altFormat = 'd.m.Y H:i'
    var $this = this
    if ($($this.unitTarget).val() == "hours") {
      var new_date = new Date( new Date( $($this.fromTarget).val() ).getTime() + (this.quantityTarget.value * 3600000) )
      dateTo.setDate(new_date, true, altFormat)
    }
  }

  updatePrice() {
    var $this = this

    // if ($($this.unitTarget).val() == "hours"){
    //   $($this.quantityTarget).prop('readonly', true)
    // } else {
    //   $($this.quantityTarget).prop('readonly', false)
    // }

    if ($(this.unitTarget).val() == "hours"  ) {
      // && $(this.calculatedTarget).prop('checked') == true
      var time_from = new Date($($this.fromTarget).val())
      var time_to = new Date($($this.toTarget).val())

      var time = new Date(time_to - time_from);
      var amount = time / 1000 / 60 / 60
      $($this.quantityTarget).val(amount)
    }

    var unit_price = $(this.unit_priceTarget).val()
    var amount = $(this.quantityTarget).val()
    var total = unit_price * amount
    $(this.total_amountTarget).val(total)
  }

  setCustomer(name, projects) {
    var $projects = JSON.stringify(projects)
    $(this.projectTarget).val(null)
    $(this.projectTarget).siblings('.selectize-control').find('.selectize-dropdown').find('.selectize-dropdown-content').find('.optgroup').hide()
    $(this.projectTarget)[0].selectize.clearOptions();
    $(this.projectTarget)[0].selectize.clear(true); 
    var project_select = $(this.projectTarget)[0].selectize

    $(projects).each(function(){
      project_select.addOption(this);
    })
  }

  setProject(name, customer) {
    
  }

  setActivity(activity) {
    $(this.unit_pricearget).val(Number(activity.price))
    $(this.unitTarget).val(activity.unit)
    this.updatePrice()
  }

  localSave() {
    $("#event_form").submit();
  }

  // disconnect() {
    // if (this.hasSelectizeTarget){
    //   $(this.selectizeTarget)[0].selectize.destroy()
    // // }
    //     project.selectize.destroy()
    //     customer.selectize.destroy()
    //     activity.selectize.destroy()
    // $('.project-selectize')[0].selectize.destroy()
    // $('.customer-selectize')[0].selectize.destroy()
    // $('.activity-selectize')[0].selectize.destroy()
    // this.fromTarget._flatpickr.destroy();
    // this.toTarget._flatpickr.destroy();
  // }

}