import { Controller } from "@hotwired/stimulus"

// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  // static targets = [ "all", "event" ]

  connect() {
    var options = {
      valueNames: [ 'name']
    };

    var customerList = new List('customer_list', options);
  }
}