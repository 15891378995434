import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [ "calendar", "users", "date", "view" ]

  connect() {
    // Create a Stripe client.
    var stripe = Stripe($('meta[name=stripe_pk]').attr("content"), {stripeAccount: $('meta[name=stripe_account_id]').attr("content"),});

    // Create an instance of Elements.
    var elements = stripe.elements();

    // var elements = stripe.elements();
// var cardElement = elements.create('card');
// cardElement.mount('#card-element');

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Create an instance of the card Element.
    var card = elements.create('card', {style: style});

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Handle form submission.
    // var form = document.getElementById('payment-form');
    // form.addEventListener('submit', function(event) {
    //   event.preventDefault();

    //   stripe.createToken(card).then(function(result) {
    //     if (result.error) {
    //       // Inform the user if there was an error.
    //       var errorElement = document.getElementById('card-errors');
    //       errorElement.textContent = result.error.message;
    //     } else {
    //       // Send the token to your server.
    //       stripeTokenHandler(result.token);
    //     }
    //   });
    // });

    // Submit the form with the token ID.
    // function stripeTokenHandler(token) {
    //   // Insert the token ID into the form so it gets submitted to the server
    //   var form = document.getElementById('payment-form');
    //   var hiddenInput = document.createElement('input');
    //   hiddenInput.setAttribute('type', 'hidden');
    //   hiddenInput.setAttribute('name', 'stripeToken');
    //   hiddenInput.setAttribute('value', token.id);
    //   form.appendChild(hiddenInput);

    //   // Submit the form
    //   form.submit();
    // }
  
    var cardholderName = document.getElementById('cardholder-name');
    var cardButton = document.getElementById('card-button');
    var clientSecret = cardButton.dataset.secret;

    cardButton.addEventListener('click', function(ev) {
      stripe.handleCardPayment(
        clientSecret, card, {
          payment_method_data: {
            billing_details: {name: cardholderName.value}
          }
        }
      ).then(function(result) {
        if (result.error) {
          var displayError = document.getElementById('card-errors');
          displayError.textContent = result.error.message;
          // Display error.message in your UI.
          console.log(result)
        } else {
          console.log(result)
          var invoice_token =  $('#invoice_token').val()
          var passcode =  $('#passcode').val()
          var request = $.ajax({
            url: `/synergi-helse/stripe/charge_end_customer?invoice_token=${invoice_token}&passcode=${passcode}`,
            dataType: 'JSON',
            method: "POST"
          })
          request.done(function(data) {
            location.reload();
          })

          // The payment has succeeded. Display a success message.
        }
      });
    });


  }
}