import { Controller } from "stimulus"
import "jquery"

export default class extends Controller {
  static targets = [ "custom", "calculated", "custom_price_selector", "calculated_price_selector", "not_billable_selector", "custom_price", "calculated_price", "total_price", "unit_price_input", "amount_input", "total_input", "customer", "project", "activity", "unit", "edit_price", "from", "to" ]

  connect() {
    var $this = this

    $('.project-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
      valueField: 'id',
      labelField: 'name',
      onChange: function() {
        event_changed = true;
        $this.getUnitPrice();
      }
    })

    $('.customer-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
      onChange: function(value, item){
        event_changed = true;
        $this.getUnitPrice();
        // $('#event_submit').click();
        var account = $('.account').data('account');
        var request = $.ajax ({
          url: `/${account}/admin/customers/${value}`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          // console.log(JSON.stringify(data))
          var name = data.name
          var projects = data.projects
          $this.setCustomer(name, projects)
        })
      },
      onClear: function(value) {
        event_changed = true;
        $this.getUnitPrice();

        var account = $('.account').data('account');
        var request = $.ajax ({
          url: `/${account}/admin/projects/`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          var name = "Internal"
          var projects = data.projects
          $this.setCustomer(name, projects)
        })
      },

    });

    $('.activity-selectize').selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
      onChange: function(value) {
        event_changed = true;
        $this.getUnitPrice();
      }
    });

    // if ($(this.custom_priceTarget).prop)
  }

  selectCustomPrice() {
    $(this.unit_price_inputTarget).prop('readonly', false)
    $(this.amount_inputTarget).prop('readonly', false)
    $(this.unitTarget).attr('readonly', false)
  }

  selectCalculatePrice() {
    $(this.unit_price_inputTarget).prop('readonly', true)
    $(this.amount_inputTarget).prop('readonly', true)
    $(this.unitTarget).attr('readonly', true)
    this.getUnitPrice();
  }


  getUnitPrice() {
    var $this = this
    var account = $('.account').data('account');
    var customer_id = $(this.customerTarget).val()
    var project_id = $(this.projectTarget).val()
    var activity_id = $(this.activityTarget).val()

    var request = $.ajax ({
      url: `/${account}/api/v1/prices/search.json/`,
      data: {
        activity_id: activity_id,
        customer_id: customer_id,
        project_id: project_id
      },
      dataType: 'JSON',
      method: "GET"
    })
    request.done(function(data) {
      if ($($this.calculatedTarget).prop('checked') == true){
        $($this.unit_price_inputTarget).val(data.price)
        $($this.unitTarget).val(data.unit)
        $this.updatePrice();
      }
    })
  }

  updatePrice() {
    var $this = this
    if ($(this.unitTarget).val() == "hours") {
      var time_from = new Date($($this.fromTarget).val())
      // console.log(time_from)
      var time_to = new Date($($this.toTarget).val())

      var time = new Date(time_to - time_from);
      // console.log(/time_to - time_from)
      var amount = time / 1000 / 60 / 60
      $(this.amount_inputTarget).val(amount)
    }

    var unit_price = $(this.unit_price_inputTarget).val()
    var amount = $(this.amount_inputTarget).val()
    var total = unit_price * amount
    $(this.total_inputTarget).val(total)
  }

  setCustomer(name, projects) {
    // console.log(name, JSON.stringify(projects))
    var $projects = JSON.stringify(projects)
    $(this.projectTarget).val(null)
    $(this.projectTarget).siblings('.selectize-control').find('.selectize-dropdown').find('.selectize-dropdown-content').find('.optgroup').hide()
    $(this.projectTarget)[0].selectize.clearOptions();
    $(this.projectTarget)[0].selectize.clear(true); 
    var project_select = $(this.projectTarget)[0].selectize

    $(projects).each(function(){
      project_select.addOption(this);
    })
    // $(this.projectTarget)[0].selectize.load(function(callback) {
    //   console.log(JSON.stringify($projects))
    //   callback(JSON.stringify($projects))
    // })
    // $(this.projectTarget).siblings('.selectize').find("[data-optgroup='" + name + "']").show()
  }

  setProject(name, customer) {
    
  }

  setActivity(activity) {
    $(this.unit_price_inputTarget).val(Number(activity.price))
    $(this.unitTarget).val(activity.unit)
    this.updatePrice()
  }

  enableEditing() {
    event.preventDefault()
    event.stopImmediatePropagation()
    $(this.amount_inputTarget).prop('readonly', false)
    $(this.unit_price_inputTarget).prop('readonly', false)
    $(this.unitTarget).prop('readonly', false)
    $(this.unitTarget).attr('readonly', false)
    // $(this.total_inputTarget).prop('readonly', false)
  }
}