import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "loggable", "type", "id" ]

  connect() {
    var account = $('.account').data('account');
    var loggable_type = $(this.loggableTarget).data('type')
    var loggable_id = $(this.loggableTarget).data('id')
    var request = $.ajax ({
      url: `/${account}/logs?loggable_type=${loggable_type}&loggable_id=${loggable_id}`,
      dataType: 'script',
      method: "GET"
    })
  }

}