import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "level1", "level2" ]
  // static values = {level1: String, level2: String}
  // static targets = [ "simple", "responsible" ]


  level2TargetConnected(element) {
    window.level2Target = $(this.level2Target).selectize({
        plugins: ['remove_button'],
      });
  }


  connect() {
    // this.change()
    // const level1Values = 

    // const delay = ms => new Promise(res => setTimeout(res, ms));

    // console.log('nested connected')
    // // if (this.hasSimpleTarget){
    //   window.level1Target = $(this.level1Target).selectize({
    //     plugins: ['remove_button'],
    //     onChange: async() => {
    //       await delay(100);
    //       get( `/${account_id}/activity_categories/activities?activity_category_id=${ category }`, {
    //         responseKind: "turbo-stream"
    //       } );
    //     }
    //   });

    //   window.level2Target = $(this.level2Target).selectize({
    //     plugins: ['remove_button'],
    //   });
    // // }

    let $this = this

      window.level1Target = $(this.level1Target).selectize({
        plugins: ['remove_button'],
        onChange: async() => {
          // await delay(100);
          let category = Array.from($($this.level1Target).val())
          // .map((s) => s.value)
          let account_id = document.querySelector('meta[name="account_id"]').content

          console.log('category', category)

          get( `/${account_id}/activity_categories/activities?activity_category_id=${ category }`, {
            responseKind: "turbo-stream"
          } );
        }
      });

  }


  change(event) {

  	console.log('changed!')



  	let category = level1Target.selectedOptions[0].value 
  	let account_id = document.querySelector('meta[name="account_id"]').content
    
    get( `/${account_id}/activity_categories/activities?activity_category_id=${ category }`, {
      responseKind: "turbo-stream"
    } );
  	  	// Get level 1 ID
  	  	// Hide? all options und}er level2

  	// Show all 
  }


  disconnect() {
    // if (this.hasSimpleTarget){
      $(this.level1Target).each(function() {
        this.selectize.destroy()
      })

      $(this.level2Target).each(function() {
        this.selectize.destroy()
      })
    // }
  }

}