import { Controller } from "@hotwired/stimulus"
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  static targets = [ "customer", "project" ]

  connect() {

    if (this.hasCustomerTarget) {
      

  var $this = this
   window.customer =  $(this.customerTarget).selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup'],
      onChange: function(value, item){
        var account = $('.account').data('account');
        var request = $.ajax ({
          url: `/${account}/customers/${value}`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          var name = data.name
          var projects = data.projects
          $this.setCustomer(name, projects)
        })
      },
      onClear: function(value) {
        var account = $('.account').data('account');
        var request = $.ajax ({
          url: `/${account}/projects/`,
          dataType: 'JSON',
          method: "GET"
        })
        request.done(function(data) {
          var name = "Internal"
          var projects = data.projects
          $this.setCustomer(name, projects)
        })
      }
    });
    }

    window.project = $(this.projectTarget).selectize({
      plugins: ['remove_button'],
      searchField: ['text', 'optgroup', 'name'],
      valueField: 'id',
      labelField: 'name'
    })

  }


  setCustomer(name, projects) {
    var $projects = JSON.stringify(projects)
    $(this.projectTarget).val(null)
    $(this.projectTarget).siblings('.selectize-control').find('.selectize-dropdown').find('.selectize-dropdown-content').find('.optgroup').hide()
    $(this.projectTarget)[0].selectize.clearOptions();
    $(this.projectTarget)[0].selectize.clear(true); 
    var project_select = $(this.projectTarget)[0].selectize

    $(projects).each(function(){
      project_select.addOption(this);
    });
  }
}



