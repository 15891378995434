import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [ "all", "event" ]

  initialize() {
    $('input[data-column]').change(function() {
      $('th[data-column="' + $(this).data('column') + '"]').toggle()
      $('td[data-column="' + $(this).data('column') + '"]').toggle()
    })
  }
}
