import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "simple", "responsible" ]

  connect() {
    var $this = this
    if (this.hasSimpleTarget){

      window.simpleTarget = $(this.simpleTarget).selectize({
        plugins: ['remove_button'],

        onItemAdd(value, $item) {
          console.log(value)
          // console.log($item)
          // document.getElementById('client_form_submit').click();
        }

      });

    }

  }


  submit() {

  }

  disconnect() {
    if (this.hasSimpleTarget){
      $(this.simpleTarget).each(function() {
        this.selectize.destroy()
      })
    }
  }
}