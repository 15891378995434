import { Controller } from "@hotwired/stimulus"
import 'jquery'

export default class extends Controller {
  static targets = ['info', 'event', 'link']

  // connect() {
    // console.log('connect')
    // console.log(this.infoTarget)
  // }

  displayInfo() {
    // console.log('hover')
    var $this = this
    var account = $('.account').data('account');
    var id = $(this.eventTarget).data('id');

    if($this.infoTarget.hasAttribute('data-original-title') == false) {
      var request = $.ajax ({
        url: `/${account}/events/${id}/custom_fields`,
        dataType: 'JSON',
        method: "GET"
      })

      request.done(function(data) {
        var html = "<div class='text-left'>"
        // console.log(data)
        data.forEach( function(element, index) {
          // console.log(element)
          var name = element.name
          var value = element.value

          html += `<div><strong>${name}:</strong> ${value}</div>`;
        });
        html += "</div>"

        // console.log(html)
        // console.log($($this.infoTarget).attr('title'))

        // $($this.infoTarget).attr('title', html)
        $($this.infoTarget).attr('data-content', html)
        $($this.infoTarget).attr('data-toggle', 'popover')
        $($this.infoTarget).popover('show')
        $($this.infoTarget).popover('update')
      })
    }
    
    $($this.infoTarget).popover('show')
  }

  displayEvent() {
    if (this.linkTarget != event.target) {
      if(!event.target.hasAttribute('type') ) {
        this.linkTarget.click();
      }
    }
  }

  hideTooltip(){
    $(this.infoTarget).popover('hide')
    $('[data-toggle="popover"]').popover('hide')
  }

}
