import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "simple", "responsible" ]

  connect() {

    let blurHandler = function() {
      console.log('blurs')
      $("#event_submit").click()
    }


    if (this.hasSimpleTarget){
      window.simpleTarget = $(this.simpleTarget).selectize({
        plugins: ['remove_button'],

      });
    }

    if (this.hasResponsibleTarget){
      window.responsibleTarget = $(this.responsibleTarget).selectize({
        plugins: ['remove_button'],
        onBlur: blurHandler(),
    });
    }

  }

  disconnect() {
    if (this.hasSimpleTarget){
      $(this.simpleTarget).each(function() {
        this.selectize.destroy()
      })
    }
    if (this.hasResponsibleTarget){
      $(this.responsibleTarget)[0].selectize.destroy()
    }
  }
}