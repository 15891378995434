import flatpickr from "flatpickr"

import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "from", "to" ]

  connect() {


    global.dateFrom = flatpickr(this.fromTarget, {
      altInput: true,
      altFormat: "F j, Y",
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      maxDate: this.toTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
          dateTo.set('minDate', dateStr);
          $('#invoice_submit').click();
      },
    });

    global.dateTo = flatpickr(this.toTarget, {
      altInput: true,
      altFormat: "F j, Y",
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      minDate: this.fromTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
          dateFrom.set('maxDate', dateStr);
          $('#invoice_submit').click();
      },
    });

    // $('[data-toggle="tooltip"]').each( function() {
    //   $(this).tooltip('hide')
    //   $(this).tooltip('dispose')
    // })
  $('[data-toggle="tooltip"]').tooltip({
    trigger: 'hover'
  })

  }

  disconnect() {

    this.fromTarget._flatpickr.destroy();
    this.toTarget._flatpickr.destroy();
  }
}
