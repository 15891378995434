import { Controller } from "@hotwired/stimulus";
// import {select2} from "select2"

export default class extends Controller {
  static targets = [ "simple", "kost", 'new']

  async connect() {
    const delay = ms => new Promise(res => setTimeout(res, ms));

    if (this.hasSimpleTarget){
      window.simpleTarget = $(this.simpleTarget).selectize({
        plugins: ['remove_button'],
        diacritics: false,

        onChange: async() => {
          console.log("change")
          document.getElementById('form-filter').dataset.turbo = 'false';
          await delay(100);
          $("#filter_submit").click()
        },

        onType: async(str) => {
          console.log(str)
          // Customize the search behavior here
          const query = str.toLowerCase();
          const $dropdown = this.dropdown;
          const options = this.options;

          // Filter options based on the query
          const filteredOptions = options.filter(function (option) {
            // Implement custom logic to handle international characters
            // You can normalize text using libraries like `unorm`
            const normalizedText = unorm.nfc(option.text.toLowerCase());
            return normalizedText.indexOf(query) === 0;
          });

          // Clear the dropdown and populate it with the filtered options
          $dropdown.empty();
          for (const option of filteredOptions) {
            $dropdown.append(this.render('option', option));
          }

          // Open the dropdown if it's not already open
          if (!$dropdown.children().length) {
            this.close();
          } else {
            this.open();
          }
        }
      });

  }


    if (this.hasKostTarget){
      console.log("cost")
      window.kostTarget = $(this.kostTarget).selectize({
        plugins: ['remove_button'],
        diacritics: false,
        onItemAdd: async() => {
          document.getElementById('form-filter').dataset.turbo = 'false';
          await delay(100);
          $("#filter_submit").click()
        },

        onType: async(str) => {
          console.log(str)
          event.preventDefault();
          // Customize the search behavior here
          const query = str.toLowerCase();
          const $dropdown = this.dropdown;
          const options = this.options;

          // Filter options based on the query
          const filteredOptions = options.filter(function (option) {
            // Implement custom logic to handle international characters
            // You can normalize text using libraries like `unorm`
            const normalizedText = unorm.nfc(option.text.toLowerCase());
            return normalizedText.indexOf(query) === 0;
          });

          // Clear the dropdown and populate it with the filtered options
          $dropdown.empty();
          for (const option of filteredOptions) {
            $dropdown.append(this.render('option', option));
          }

          // Open the dropdown if it's not already open
          if (!$dropdown.children().length) {
            this.close();
          } else {
            this.open();
          }
        }
      });
    }

    if (this.hasNewTarget) {

      $(this.newTarget).select2({
         
        change: async() => {
           $("#filter_submit").click();
        }
      });
    }
  }

  disconnect() {
    if (this.hasSimpleTarget){
      $(this.simpleTarget)[0].selectize.destroy()
    }

    if (this.hasKostTarget){
      $(this.kostTarget)[0].selectize.destroy()
    }
  }
}