import { Controller } from "stimulus"
var $ = require("jquery");
import List from 'list.js'


export default class extends Controller {
  static targets = [ "all", "event" ]

  connect() {
    var UserListOptions = {
      valueNames: [ 'user', {name: 'billed', attr: 'data-billed'}, {name: "yield", attr: "data-yield"}, {name: "cost", attr: "data-cost"}, 'hours', 'planned_hours']
    };

    var userList = new List('user-list', UserListOptions);
  }
}