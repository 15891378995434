import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "commentable", "type", "id" ]

  connect() {
    var account = $('.account').data('account');
    var commentable_type = $(this.commentableTarget).data('type')
    var commentable_id = $(this.commentableTarget).data('id')
    var request = $.ajax ({
      url: `/comments?commentable_type=${commentable_type}&commentable_id=${commentable_id}`,
      dataType: 'script',
      method: "GET"
    })

  }

}