import { Controller } from "@hotwired/stimulus"

import Rails from '@rails/ujs';


export default class extends Controller {
  static targets = [ "new_field", 'add_custom_fields' ]

  connect() {
    var account = $('.account').data('account');
    var query = window.location.search
    var url = `/${account}/reports/team_table${query}`


    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'script'
    })

    // responds with js.erb
  }

}