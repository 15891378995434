
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // $('[data-toggle="tooltip"]').tooltip()
    // $('.tooltip-toggle').tooltip()

    $('.user-mention').click(function(event) {
      console.log(event)
      console.log(event.target)
      event.preventDefault();
      event.stopPropagation();

      var url = $(event.target).closest('.user-mention').attr('href')
      console.log(url)

      $.ajax({
        url: url,
        dataType: 'script'
      });
    })
  }

  disconnect() {
    // $('[data-toggle="tooltip"]').tooltip('hide')
    // $('[data-toggle="tooltip"]').tooltip('dispose')
    // $('.tooltip-toggle').tooltip('hide')
    // $('.tooltip-toggle').tooltip('dispose')
    $('.modal').modal('dispose')
  }
}