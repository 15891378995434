// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
// import Flatpickr from "stimulus-flatpickr";
// require("@rails/ujs")

import flatpickr from "flatpickr"
// import "flatpickr/dist/flatpickr.css" // apparently have to put css here as well

// you can also import a translation file
// import { French } from "flatpickr/dist/l10n/fr.js";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/dark.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
// import { Controller } from "stimulus"
import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "from", "to" ]

  initialize() {
    console.log('initialize')

    const altFormat = 'd.m.Y'

    window.dateFrom = flatpickr(this.fromTarget, {
      altInput: true,
      allowInput: true,
      altFormat,

      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      maxDate: this.toTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
          dateTo.set('minDate', dateStr);
      },
    });

    window.dateTo = flatpickr(this.toTarget, {
      altInput: true,
      allowInput: true,
      altFormat,
      
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      minDate: this.fromTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
          dateFrom.set('maxDate', dateStr);
      },
    });


    dateFrom._input.addEventListener('input', (event) => {
      const value = dateFrom._input.value
      const parsedDate = dateFrom.parseDate(value, altFormat)
      const formattedDate = dateFrom.formatDate(parsedDate, altFormat)
      if(value === formattedDate) dateFrom.setDate(value, true, altFormat)
    }, true)

    dateTo._input.addEventListener('input', (event) => {
      const value = dateTo._input.value
      const parsedDate = dateTo.parseDate(value, altFormat)
      const formattedDate = dateTo.formatDate(parsedDate, altFormat)
      if(value === formattedDate) dateTo.setDate(value, true, altFormat)
    }, true)

  }

  disconnect() {
    this.fromTarget._flatpickr.destroy();
    this.toTarget._flatpickr.destroy();
  }
}
