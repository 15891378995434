import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectize", "submit" ]

  connect() {
    var $this = this
    if (this.hasSelectizeTarget){
      window.selectizeTarget = $(this.selectizeTarget).selectize({
        plugins: ['remove_button'],
        onChange: function() {
          $($this.submitTarget).click()
        }
      });
    }
  }

  disconnect() {
    if (this.hasSelectizeTarget){
      $(this.selectizeTarget)[0].selectize.destroy()
    }
  }
}