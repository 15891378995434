import { Controller } from "@hotwired/stimulus"
import "jquery"

export default class extends Controller {
  static targets = [ "status", "id", "event" ]

  connect() {
    if (this.hasStatusTarget) {
      if ($(this.statusTarget).val() == 'completed' || $(this.statusTarget).val() == 'approved') {
        $(this.statusTarget).prop('checked', true)
      } else {
        $(this.statusTarget).prop('checked', false)
      }
    }
  }

  updateStatus() {
    var account = $('.account').data('account');

    if ($(this.statusTarget).prop('checked') == true) {
      var status = "completed"
    } else {
      var status = "planned"
    }
    var id = $(this.idTarget).data('id')

    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var request = $.ajax ({
      url: `/${account}/events/${id}`,
      dataType: 'script',
      method: "PATCH",
      data: {'event[status]': status}
      // dataType: 'script',
      })

      // request.done(function(data) {
        // $(this.eventTarget).addClass('list-group-item-info')
        // $(this.eventTarget).delay(500).hide(400)
      // })
  }

}