import { Controller } from "@hotwired/stimulus"

import List from 'list.js'

export default class extends Controller {
  static targets = [ "customer_id", "event", "amount" ]

  connect() {
    var options = {
      valueNames: [ {name: 'name', attr: 'data-name'}, {name: 'amount', attr: 'data-amount'}, 'events' ]
    };

    var customerList = new List('customer_list', options);
  }

}