import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'excluded', 'included', "conditions_radio", "only_new_events_radio", "conditions", "update_variants", "include_all_variants", "include_all_variants_except", "include_all_variants_only_these", "update_variants_div", "excluded_customers_div", "included_customers_div"  ]

  connect() {
    if (this.hasExcludedTarget){
      window.excluded = $(this.excludedTarget).selectize({
        plugins: ['remove_button'],
      });
    }

    if (this.hasIncludedTarget){
      window.included = $(this.includedTarget).selectize({
        plugins: ['remove_button'],
      });
    }

  }

  toggleUpdateVariants() {
    if ($(event.target).prop('checked') == true) {

      $(this.update_variants_divTarget).show()
      $(this.include_all_variantsTarget).prop("checked", true)
    } else {

      $(this.update_variants_divTarget).hide()
      $(this.excluded_customers_divTarget).hide()
      $(this.included_customers_divTarget).hide()

      $(this.include_all_variantsTarget).prop("checked", false)
      $(this.include_all_variants_exceptTarget).prop("checked", false)
      $(this.include_all_variants_only_theseTarget).prop("checked", false)

      included[0].selectize.clear()
      excluded[0].selectize.clear()

    }
  }

  toggleAllVariants() {
      // $(this.update_variants_divTarget).hide()
      $(this.excluded_customers_divTarget).hide()
      $(this.included_customers_divTarget).hide()

      $(this.include_all_variantsTarget).prop("checked", false)
      $(this.include_all_variants_exceptTarget).prop("checked", false)
      $(this.include_all_variants_only_theseTarget).prop("checked", false)
      included[0].selectize.clear()
      excluded[0].selectize.clear()
  }

  toggleExcludedCustomers() {
    if ($(event.target).prop('checked') == true) {
      $(this.excluded_customers_divTarget).show()
      $(this.included_customers_divTarget).hide()

      included[0].selectize.clear()
    } else {
      $(this.excluded_customers_divTarget).hide()
      excluded[0].selectize.clear()
    }
  }

  toggleIncludedCustomers() {
    if ($(event.target).prop('checked') == true) {
      $(this.included_customers_divTarget).show()
      $(this.excluded_customers_divTarget).hide()

      excluded[0].selectize.clear()

    } else {
      $(this.included_customers_divTarget).hide()
      included[0].selectize.clear()
    }
  }


  setConditions() {
    $(this.conditionsTarget).show();
  }

  unsetConditions() {
    $(this.conditionsTarget).hide();
  }

  disconnect() {
    excluded[0].selectize.destroy()
    included[0].selectize.destroy()
  }
}
