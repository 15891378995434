import { Controller } from "@hotwired/stimulus"
var $ = require("jquery");

export default class extends Controller {
  static targets = [ "all", "event" ]


  toggleAll() {
    // console.log('check')
    // console.log($(this.eventTargets))
    if ($(this.allTarget).prop('checked') == false) {
      $(this.eventTargets).prop('checked', false)
      // $(this.allTarget).prop('checked', false)
      this.toggle()
    }
    if ($(this.allTarget).prop('checked') == true) {
      $(this.eventTargets).prop('checked', true)
      // $(this.allTarget).prop('checked', false)
      this.toggle()
    }
  }

  toggle() {
    // console.log($(this.eventTargets).filter(':checked').length)
    if ( $(this.eventTargets).filter(':checked').length > 0 ) {
      $("#create_invoices").show()
      $(".checkbox-toggle-target").prop("disabled", false)
      $("#update_prices").prop('disabled', false)
    } else {
      $("#create_invoices").hide()
      $(".checkbox-toggle-target").prop("disabled", true)
      $("#update_prices").prop('disabled', true)
    }

    var array = $(this.eventTargets).filter(":checked").map(function() {
      return $(this).val();}).get()
    var myHtml = "";

    $.each(array, function(index, value) {
      myHtml += "<input class='checked-ids' type='hidden' name='ids[]' value=" + value + ">";
     })

    $(".ids").each(function(){
      $(this).html( myHtml )
    })

    $(this.eventTargets).filter(":not(:checked)").map(function() {
      $(this).closest('tr').removeClass('table-primary')
    })

    $(this.eventTargets).filter(":checked").map(function() {
      $(this).closest('tr').addClass('table-primary')
    })

  }

  highlight() {
    $(this.eventTargets).filter(":checked").map(function() {
      $(this).closest('tr').addClass('table-active')
    })
  }

}
