import { Controller } from "@hotwired/stimulus"

// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  // static targets = [ "all", "event" ]

  connect() {

    var account = $('.account').data('account');
    var q =  $('#params').data('params')

    $('#project_q').keyup(delay(function (e) {
      // console.log('Time elapsed!', this.value);
      $('#q_submit').click();

    }, 200));



    // $('#project_q').change(function() {
    //   $('#q_submit').click();
    // }).delay(200)

    // $('#project_q').keyup(function() {
    //   $('#q_submit').click();
    // }).delay(200)

    // $('#project_q').keydown(function() {
    //   $('#q_submit').click();
    // }).delay(200)


    function delay(callback, ms) {
      var timer = 0;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, ms || 0);
      };
    }


    var request = $.ajax ({
      url: `/${account}/projects?q=${q}`,
      dataType: 'script',
      method: "GET"
    })




    request.done(function() {
      var options = {
        valueNames: [ 'name', 'customer']
      };

      var projectList = new List('project_list', options);
    })

  }

}