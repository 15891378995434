import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = [ "toggle", "details"]

  connect() {
    this.toggle()
  }

  toggle() {
    if ($(this.toggleTarget).prop('checked') == true ) {
      $(this.detailsTarget).show()
    } else {
      $(this.detailsTarget).hide()
    }
  }
}