import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  connect() {

    $(window).on('keydown', function(e) {
      console.log(e.keyCode)
      console.log(String.fromCharCode(e.keyCode))

      // console.log(e.target)
      
      // switch(e.keyCode) {
      //   case 78:
      //     // N - new event
          
      //     break;
      //   case :
      //     // code block
      //     break;
      //   default:
      //     // code block
      // }


    })

  }
}