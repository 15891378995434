import { Controller } from "@hotwired/stimulus"
// require( 'datatables.net-fixedcolumns' )
import List from 'list.js'


export default class extends Controller {
  // static targets = [ "all", "event" ]

  connect() {
    var options = {
      valueNames: [ 'name', 'role', 'position', 'user_number', 'customer_name', 'client', 'email']
    };

    var userList = new List('user_list', options);
  }
}