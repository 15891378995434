import { Controller } from "@hotwired/stimulus"
import 'jquery'

export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
    // console.log('connect')
      $('[data-toggle="tooltip"]').tooltip('hide')

    $('[data-toggle="tooltip"]').tooltip('dispose')

    $('[data-toggle="tooltip"]').tooltip()

    // console.log(this.checkboxTarget)
  }

  toggleHidden() {
    if ($(this.checkboxTarget).prop('checked') == true) {
      $(this.checkboxTarget).prop('checked', false)
    } else {
      $(this.checkboxTarget).prop('checked', true)
    }
    $('#invoice_submit').click();
  }

  disconnect () {
      $('[data-toggle="tooltip"]').tooltip('hide')

    $('[data-toggle="tooltip"]').tooltip('dispose')
  }
}