// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
// import Flatpickr from "stimulus-flatpickr";
import flatpickr from "flatpickr"
// import "flatpickr/dist/flatpickr.css" // apparently have to put css here as well
// require ("flatpickr/dist/flatpickr.css") // apparently have to put css here as well

// you can also import a translation file
// import { French } from "flatpickr/dist/l10n/fr.js";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/dark.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
import { Controller } from "@hotwired/stimulus"
import "jquery"

export default class extends Controller {
  static targets = [ "from", "to", "alldaytoggle", "simple" ]

  initialize() {
    global.dateFrom = flatpickr(this.fromTarget, {
      altInput: true,
      altFormat: "F j, Y H:i",
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d H:i",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      enableTime: true,
      time_24hr: true,
      maxDate: this.toTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
          dateTo.set('minDate', dateStr)
      },
    });

    global.dateTo = flatpickr(this.toTarget, {
      altInput: true,
      altFormat: "F j, Y H:i",
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d H:i",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      enableTime: true,
      time_24hr: true,
      minDate: this.fromTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
          dateFrom.set('maxDate', dateStr)
      },
    });
  }

  toggleAllDay() {
    if ($(this.alldaytoggleTarget).is(":checked") == true) {
      dateFrom.set('enableTime', false)
      dateFrom.set('altFormat', "F j, Y ")
      dateTo.set('enableTime', false)
      dateTo.set('altFormat', "F j, Y ")
    } else {
      dateFrom.set('enableTime', true)
      dateFrom.set('altFormat', "F j, Y H:i")
      dateTo.set('enableTime', true)
      dateTo.set('altFormat', "F j, Y H:i")
    }
  }

  disconnect() {
    this.fromTarget._flatpickr.destroy();
    this.toTarget._flatpickr.destroy();
  }
}
