import { Controller } from "@hotwired/stimulus"
var $ = require("jquery");

export default class extends Controller {
  static targets = [ "new_field", 'add_custom_fields' ]

  connect() {
  }


  addExistingField() {

  }

  addNewField() {
    this.newField($(event.target).data('field-type'))
  }

  newField(field_type) {
    var $this = this
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    var account = $('.account').data('account')
    var request = $.ajax ({
      url: `/${account}/defined_custom_fields/`,
      dataType: 'JSON',
      data: {
        'defined_custom_field[field_type]': field_type,
        'defined_custom_field[customizable_model]': 'event',
        'defined_custom_field[name]': field_type,
        'defined_custom_field[account_id]': account
      },
      method: "POST"
    })

    request.done(function(data) {
      $this.addField(data.id, data.field_type)
    })
  }

  addField(id, field_type) {
    var $this = this
    var defined_custom_field_id = id
    var time = new Date().getTime()

    // console.log($this.new_fieldTarget)
    $this.new_fieldTarget.content.querySelector('.defined_field_id').value = defined_custom_field_id
    $($this.new_fieldTarget.content).find('.field_type').val(field_type)
    $this.new_fieldTarget.content.querySelector("#name").value = field_type
    console.log($this.new_fieldTarget.content.querySelector("#name"))
    console.log($this.new_fieldTarget.content.querySelector("#name").value)
    $($this.new_fieldTarget.content).find('#field-name').text(field_type)

    if (field_type == "text") {
      var field = `<textarea class="form-control" data-target="event.input " data-action="change->event#updateEvent" name="event[custom_fields_attributes][${time}][text]" id="event_custom_fields_attributes_${time}_text"></textarea>`;
    }

    if (field_type == "date") {
      var field = `<input type='text' data-controller="simple-flatpickr" data-target="simple-flatpickr.simple" name="event[custom_fields_attributes][${time}][date]" id="event_custom_fields_attributes_${time}_date">`;
    }

    if (field_type == "datetime") {
      var field = `<input type='text' data-controller="simple-flatpickr" data-target="simple-flatpickr.time" name="event[custom_fields_attributes][${time}][date]" id="event_custom_fields_attributes_${time}_date">`;
    }

    if (field_type == "decimal") {
      var field = `<input type='number' step="0.01" name="event[custom_fields_attributes][${time}][number]" id="event_custom_fields_attributes_${time}_number">`;
    }

    if (field_type == "integer") {
      var field = `<input type='number' step="1" name="event[custom_fields_attributes][${time}][number]" id="event_custom_fields_attributes_${time}_number">`;
    }

    if (field_type == "boolean") {
      var field = `<div class="my-2">
        <div class="custom-control custom-switch">
          <input name="event[custom_fields_attributes][${time}][boolean]" type="hidden" value="0"><input class="custom-control-input" type="checkbox" value="1" name="event[custom_fields_attributes][${time}][boolean]" id="event_custom_fields_attributes_${time}_boolean">
          <label class="custom-control-label" for="event_custom_fields_attributes_${time}_boolean">${field_type}</label>
        </div>
      </div>`;
    }



    $($this.new_fieldTarget.content).find('#custom-fields-content').html(field)

    var content = $this.new_fieldTarget.innerHTML.replace(/NEW_RECORD/g, time)

    $this.add_custom_fieldsTarget.insertAdjacentHTML('beforebegin', content)

  }

}