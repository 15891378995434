import { Controller } from "stimulus"
var $ = require("jquery");
require( 'datatables.net' )
import List from 'list.js'


export default class extends Controller {
  static targets = [ "all", "event" ]

  connect() {
    // var ActivityListOptions = {
    //   valueNames: [ 'customer', 'customer-activity', 'project', 'project-activity']
    // };

    // var activityList = new List('activity-list', ActivityListOptions);
     window.datatable = $('.datatable').DataTable({
       paging: false,
       searching: false
     });
  }

  disconnect() {
    datatable.destroy();
  }
}