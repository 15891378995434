// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
// import Flatpickr from "stimulus-flatpickr";
import flatpickr from "flatpickr"
// import "flatpickr/dist/flatpickr.css" // apparently have to put css here as well

// you can also import a translation file
// import { French } from "flatpickr/dist/l10n/fr.js";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/dark.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "range"]

  initialize() {
    flatpickr(this.rangeTarget, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      mode: "range",
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
    });
  }

  disconnect() {
    this.rangeTarget._flatpickr.destroy();
  }
}
