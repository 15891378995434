// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
// import Flatpickr from "stimulus-flatpickr";
// require("@rails/ujs")

import flatpickr from "flatpickr"
// import "flatpickr/dist/flatpickr.css" // apparently have to put css here as well

// require ("flatpickr/dist/flatpickr.css") // apparently have to put css here as well

// you can also import a translation file
// import { French } from "flatpickr/dist/l10n/fr.js";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/dark.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "from", "to", "alldaytoggle", "updatefrom", "updateto", "updateallday" ]

  initialize() {
    global.dateFrom = flatpickr(this.fromTarget, {
      altInput: true,
      altFormat: "F j, Y H:i",
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d H:i",
      enableTime: true,
      time_24hr: true,
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      maxDate: this.toTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
        // console.log('close from')
          // dateTo.set('minDate', dateStr);
          $('#event_submit').click();
          // Rails.fire(this.form, 'submit');
      },
    });

    global.dateTo = flatpickr(this.toTarget, {
      altInput: true,
      altFormat: "F j, Y H:i",
      altInputClass: 'form-control input bg-white',
      dateFormat: "Y-m-d H:i",
      enableTime: true,
      time_24hr: true,
      locale: {
          firstDayOfWeek: 1 // start week on Monday
      },
      minDate: this.fromTarget.value,
      onChange:  function(selectedDates, dateStr, instance) {
        console.log('close to')
          // dateFrom.set('maxDate', dateStr);
          // console.log($(this.toTarget).closest('form'))
          // $("#event_to").closest('form').submit();
          // this.updatetoTarget.click();
          $('#event_submit').click();
      },
    });

    // console.log($(this.alldaytoggleTarget).is(":checked"))
    // if ($(this.alldaytoggleTarget).is(":checked") == true) {
    //   dateFrom.set('enableTime', false)
    //   dateFrom.set('altFormat', "F j, Y")
    //   dateTo.set('enableTime', false)
    //   dateTo.set('altFormat', "F j, Y ")
    // } else {
    //   dateFrom.set('enableTime', true)
    //   dateFrom.set('altFormat', "F j, Y H:i")
    //   dateTo.set('enableTime', true)
    //   dateTo.set('altFormat', "F j, Y H:i")
    // }
  }

  toggleAllDay() {
    if ($(this.alldaytoggleTarget).is(":checked") == true) {
      dateFrom.set('enableTime', false)
      dateFrom.set('altFormat', "F j, Y ")
      dateTo.set('enableTime', false)
      dateTo.set('altFormat', "F j, Y ")
      Rails.fire(this.form, 'submit');
    } else {
      dateFrom.set('enableTime', true)
      dateFrom.set('altFormat', "F j, Y H:i")
      dateTo.set('enableTime', true)
      dateTo.set('altFormat', "F j, Y H:i")
      Rails.fire(this.form, 'submit');
    }
  }



  disconnect() {
    this.fromTarget._flatpickr.destroy();
    this.toTarget._flatpickr.destroy();
  }
}
